export const routes = [
    {
        path: '/validities',
        name: 'validities.browse',
        component: () => import(/* webpackChunkName: "ValiditiesBrowse" */ '@/views/app/ValiditiesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/validities/create',
        name: 'validities.create',
        component: () => import(/* webpackChunkName: "ValiditiesActions" */ '@/views/app/ValiditiesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/validities/:id/edit',
        name: 'validities.edit',
        component: () => import(/* webpackChunkName: "ValiditiesActions" */ '@/views/app/ValiditiesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/validities/:id/delete',
        name: 'validities.delete',
        component: () => import(/* webpackChunkName: "ValiditiesActions" */ '@/views/app/ValiditiesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]